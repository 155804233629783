import { useEffect, useState } from "react";
import { config } from "../../../config";
import { useAuthContext } from "../../../context/AuthContext";
import fetcher from "../../../utils/fetcher";
import BtnWithDialog from "../../ui/BtnWithDialog";
import createDetailedErrorMessage from "../../../utils/detailMessageError";
import del from "../../../assets/il-delete.svg";
import { useNavigate } from "react-router-dom";

const DeleteCustomerButton = ({ customerName, customerId }) => {
  const { client, setToasts } = useAuthContext();
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const text = (
    <div>
      Delete Customer<strong> {`${customerName}`}</strong>?
    </div>
  );

  const smallText = (
    <div>
      {
        "This customer's variants and program participations will also be removed"
      }
    </div>
  );

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed delete customer",
          delay: 50000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  const onDelete = () => {
    fetcher({
      url: `${config.api.phoenix}/customers/${customerId}`,
      method: "DELETE",
      payload: {},
      customerId: customerId,
      clientId: client?.id,
    })
      .then(() => {
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            type: "success-delete",
            variant: "success",
            img: del,
            delay: 50000,
            titleText: "Customer Deleted",
            text: (
              <div>
                {"Customer "} <strong>{customerName}</strong>{" "}
                {
                  " is deleted. It will no longer appear in search results and can not be viewed or edited"
                }
              </div>
            ),
          },
        ]);
        navigate("/customers");
      })
      .catch((err) => {
        setError(err);
      });
  };

  return (
    <BtnWithDialog
      modalSize="md"
      title={""}
      text={text}
      smallText={smallText}
      onConfirm={onDelete}
    />
  );
};

export default DeleteCustomerButton;
