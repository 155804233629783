import React, { useEffect, useState, createContext, useContext } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import fetcher from "../../utils/fetcher";
import { config } from "../../config";
import ImgBox from "./ImgBox";
import { BsCheck2, BsPencil, BsX } from "react-icons/bs";
import { useAuthContext } from "../../context/AuthContext";
import createDetailedErrorMessage from "../../utils/detailMessageError";

export const EditableContext = createContext({
  editable: false,
  setEditable: () => {},
  setError: () => {},
  onSubmit: () => {},
});

const FormComponent = ({
  dataUrl = "",
  method = ["put", "delete", "post"],
  refetch,
  content, //all content of the object including fields we want to render. Important for onSubmit payload
  children,
  formSettings,
  customOnSubmit,
  className,
}) => {
  const [error, setError] = useState(null);

  const defaultValues = formSettings
    ? Object.keys(formSettings).reduce((acc, key) => {
        const value = formSettings[key].value;
        acc[key] = value;
        return acc;
      }, {})
    : content;
  const { client, setToasts } = useAuthContext();

  const [editable, setEditable] = useState(false);

  const methods = useForm({ defaultValues });
  const { isDirty } = methods.formState;

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to submit form",
          delay: 50000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty && editable) {
        event.preventDefault();
        return (event.returnValue = "");
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty, editable]);

  const onSubmit = (data) => {
    if (customOnSubmit) {
      customOnSubmit(data);
      setEditable(false);
    } else {
      fetcher({
        url: `${dataUrl}`,
        method: method,
        payload: { ...content, ...data },
        customerId: config.auth.testCustomer,
        clientId: client?.id,
      })
        .then((res) => {
          refetch();
          setEditable(false);
        })
        .catch((err) => {
          setError(err);
          setEditable(false);
        });
    }
    methods.reset(data);
  };

  return (
    <EditableContext.Provider
      value={{ editable, setEditable, setError, onSubmit }}
    >
      <FormProvider {...methods}>
        <Form
          className={className}
          onSubmit={methods.handleSubmit(onSubmit)}
          onReset={() => methods.reset({ ...defaultValues })}
        >
          {children}
        </Form>
      </FormProvider>
    </EditableContext.Provider>
  );
};

const Field = ({
  fieldName,
  type,
  formSettings,
  fieldLabel,
  fieldRequired,
  fieldPlaceholder,
  fieldOptions,
  fieldArrayOptions,
  fieldDefaultValue,
  fieldMaxLength,
  fieldMaxNumber,
  fieldMinNumber,
  validationRules,
}) => {
  const {
    register,
    watch,
    formState: { errors, isSubmitted, dirtyFields },
  } = useFormContext();

  const fieldSettings = formSettings ? formSettings[fieldName] : {};
  const { editable } = useContext(EditableContext);
  const isFieldDirty = (fieldName) => dirtyFields[fieldName];
  const required = fieldSettings.required ?? fieldRequired ?? false;
  const selectOptions = fieldSettings.selectOptions ?? fieldOptions ?? []; //this one is for array of objects
  const selectArray = fieldSettings.selectArray ?? fieldArrayOptions ?? []; //this one is just for an array
  const imageId = fieldSettings.imageId ?? null;
  const imageHeight = fieldSettings.imageHeight ?? 40;
  const imageWidth = fieldSettings.imageWidth ?? 40;
  const label = fieldSettings.label ?? fieldLabel ?? null;
  const placeholder = fieldSettings.placeholder ?? fieldPlaceholder ?? null;
  const hasText =
    fieldSettings?.text !== null && fieldSettings?.text !== undefined;
  const defaultValue = fieldSettings.defaultValue ?? fieldDefaultValue ?? null; //default value for when register cannot be used
  const maxLength = fieldSettings.maxLength ?? fieldMaxLength ?? null; //max length of characters for a field
  const maxNumber = fieldSettings.maxNumber ?? fieldMaxNumber ?? null; //maximum value for a number field
  const minNumber = fieldSettings.minNumber ?? fieldMinNumber ?? null; //minimal value for a number field
  switch (type) {
    case "text":
      return (
        <FloatingLabel
          controlId={fieldName}
          label={label}
          className="floating-label"
        >
          <Form.Control
            style={
              isFieldDirty(fieldName)
                ? { backgroundColor: "rgba(0, 255, 0, 0.2)" }
                : {}
            }
            type="text"
            defaultValue={defaultValue || ""}
            {...register(
              fieldName,
              validationRules || {
                required: required,
                maxLength: maxLength,
              }
            )}
            placeholder={placeholder}
            isInvalid={errors[fieldName]}
            isValid={isSubmitted && !errors?.code}
            readOnly={!editable}
            disabled={!editable || fieldName === "id"}
          />
          <Form.Control.Feedback type="invalid">
            {errors[fieldName] && errors[fieldName].message}
          </Form.Control.Feedback>
          {hasText ? <Form.Text muted>{fieldSettings.text}</Form.Text> : null}
        </FloatingLabel>
      );
    case "select":
      return (
        <FloatingLabel
          controlId={fieldName}
          label={label}
          className="floating-label"
        >
          <Form.Select
            defaultValue={defaultValue || 0}
            {...register(fieldName)}
            disabled={!editable}
            style={
              isFieldDirty(fieldName)
                ? { backgroundColor: "rgba(0, 255, 0, 0.2)" }
                : {}
            }
          >
            {selectOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
            {selectArray.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
          {hasText ? <Form.Text muted>{fieldSettings.text}</Form.Text> : null}
        </FloatingLabel>
      );
    case "checkbox":
      return (
        <Form.Check
          style={
            isFieldDirty(fieldName)
              ? { backgroundColor: "rgba(0, 255, 0, 0.2)" }
              : {}
          }
          type="checkbox"
          id={`check-${fieldName}`}
          label={label}
          {...register(fieldName)}
          disabled={!editable}
        />
      );
    case "switch":
      return (
        <Form.Check
          style={
            isFieldDirty(fieldName)
              ? { backgroundColor: "rgba(0, 255, 0, 0.2)" }
              : {}
          }
          type={type}
          id={`switch-${fieldName}`}
          label={label}
          {...register(fieldName)}
          disabled={!editable}
        />
      );
    case "textarea":
      return (
        <FloatingLabel label={label} controlId={fieldName}>
          <Form.Control
            key={`control-${fieldName}`}
            placeholder={label}
            as={type}
            {...register(
              fieldName,
              validationRules || {
                required: required,
                maxLength: maxLength,
              }
            )}
            style={
              isFieldDirty(fieldName)
                ? { backgroundColor: "rgba(0, 255, 0, 0.2)", height: "200px" }
                : { height: "200px" }
            }
            isInvalid={errors[fieldName]}
            readOnly={!editable}
            disabled={!editable}
          />
          <Form.Control.Feedback type="invalid">
            {errors[fieldName] && errors[fieldName].message}
          </Form.Control.Feedback>
          {hasText ? <Form.Text muted>{fieldSettings.text}</Form.Text> : null}
        </FloatingLabel>
      );
    case "image":
      const fileId = watch(imageId);
      return (
        <ImgBox
          imgId={fileId}
          width={imageWidth}
          height={imageHeight}
          className={`mb-2 border input-border rounded ${
            editable ? "bg-white" : ""
          }`}
        />
      );
    case "number":
      return (
        <FloatingLabel
          controlId={fieldName}
          label={label}
          className="floating-label"
        >
          <Form.Control
            type="number"
            defaultValue={defaultValue || ""}
            {...register(
              fieldName,
              validationRules || {
                required: required,
                maxLength: maxLength,
              }
            )}
            isInvalid={errors[fieldName]}
            min={minNumber || 0}
            max={maxNumber || 9999}
            required={required}
            disabled={!editable}
            step="1"
          />
          <Form.Control.Feedback type="invalid">
            {errors[fieldName] && errors[fieldName].message}
          </Form.Control.Feedback>
          {hasText ? <Form.Text muted>{fieldSettings.text}</Form.Text> : null}
        </FloatingLabel>
      );
    default:
      return (
        <FloatingLabel
          controlId={fieldName}
          label={label}
          className="floating-label"
        >
          <Form.Control
            style={
              isFieldDirty(fieldName)
                ? { backgroundColor: "rgba(0, 255, 0, 0.2)" }
                : {}
            }
            type="text"
            defaultValue={defaultValue || ""}
            {...register(
              fieldName,
              validationRules || {
                required: required,
                maxLength: maxLength,
              }
            )}
            placeholder={placeholder}
            isInvalid={errors[fieldName]}
            isValid={isSubmitted && !errors?.code}
            readOnly={!editable}
            disabled={!editable || fieldName === "id"}
          />
          <Form.Control.Feedback type="invalid">
            {errors[fieldName] && errors[fieldName].message}
          </Form.Control.Feedback>
          {hasText ? <Form.Text muted>{fieldSettings.text}</Form.Text> : null}
        </FloatingLabel>
      );
  }
};

const DirtyFields = () => {
  const {
    formState: { dirtyFields },
  } = useFormContext();
  return (
    Object.keys(dirtyFields).length > 0 && (
      <div className="dirty-fields-bubble">
        {Object.keys(dirtyFields).length}
      </div>
    )
  );
};

const EditButton = ({ formSettings }) => {
  const defaultValues = Object.keys(formSettings).reduce((acc, key) => {
    acc[key] = formSettings[key].value || ""; // Use an empty string as a fallback
    return acc;
  }, {});
  const {
    reset,
    formState: { isDirty },
  } = useFormContext();
  const { editable, setEditable } = useContext(EditableContext);

  return editable ? (
    <div className="text-end">
      <Button
        variant="secondary"
        type="button"
        onClick={() => {
          reset({ ...defaultValues });
          setEditable(false);
        }}
      >
        <BsX className="btn-icon me-1" /> Cancel
      </Button>
      <Button variant="success" type="submit" disabled={!isDirty}>
        <BsCheck2 className="btn-icon me-1" /> Save changes
      </Button>
    </div>
  ) : (
    <div className="text-end">
      <Button type="button" variant="success" onClick={() => setEditable(true)}>
        <BsPencil className="btn-icon me-1" /> Edit
      </Button>
    </div>
  );
};

const EditModal = ({ setShowModal, variant, size, children }) => {
  const { setEditable } = useContext(EditableContext);

  const handleOnClick = () => {
    setEditable(true);
    setShowModal(true);
  };

  return (
    <Button variant={variant} size={size} onClick={() => handleOnClick()}>
      {children}
    </Button>
  );
};

const SaveModal = ({ variant, size }) => {
  const { onSubmit } = useContext(EditableContext);
  const {
    handleSubmit,
    formState: { isDirty },
  } = useFormContext();

  return (
    <Button
      variant={variant}
      size={size}
      type="submit"
      disabled={!isDirty}
      onClick={handleSubmit(onSubmit)}
    >
      <BsCheck2 className="btn-icon me-1" /> Save changes
    </Button>
  );
};

const CancelModal = ({ setShowModal, formData, variant, size }) => {
  const { setEditable } = useContext(EditableContext);
  const { reset } = useFormContext();

  const handleOnClick = () => {
    reset({ ...formData });
    setEditable(false);
    setShowModal(false);
  };

  return (
    <Button variant={variant} size={size} onClick={handleOnClick}>
      <BsX className="btn-icon me-1" /> Cancel
    </Button>
  );
};

FormComponent.SaveModal = SaveModal;
FormComponent.CancelModal = CancelModal;
FormComponent.EditModal = EditModal;
FormComponent.Field = Field; //adds a new field to the component
FormComponent.DirtyFields = DirtyFields; //counts amount of dirty fields of the component
FormComponent.EditButton = EditButton; //allows user to edit the form

export default FormComponent;
