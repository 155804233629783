import React, { useState, useContext, useMemo } from "react";
import { config } from "../config";

export const AuthContext = React.createContext();
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [userSettings, setUserSettings] = useState(null);
  const [client, setClient] = useState(
    JSON.parse(localStorage.getItem("client")) ?? {
      id: config.auth.testClient,
      code: "TEST",
      customer: null,
    }
  );
  const [toasts, setToasts] = useState([]);

  const authStore = useMemo(() => {
    const login = () => {
      window.location.href = `${config.auth.bffUrl}/auth/login?returnUrl=${config.auth.appUrl}/onlogin`;
    };

    const logout = () => {
      window.location.href = `${config.auth.bffUrl}/auth/logout?returnUrl=${config.auth.appUrl}/onlogout`;
    };

    const isFavorite = ({ viewId }) => {
      return (
        userSettings &&
        !!userSettings?.favoriteViews.filter((v) => v.id === +viewId)?.length
      );
    };

    return {
      login,
      logout,
      client,
      setClient,
      userSettings,
      setUserSettings,
      isFavorite,
      toasts,
      setToasts,
    };
  }, [client, userSettings, toasts]);

  return (
    <AuthContext.Provider value={authStore}>{children}</AuthContext.Provider>
  );
};
