import React, { useState } from "react";
import { ListGroup, Offcanvas } from "react-bootstrap";
import { FaStore } from "react-icons/fa6";
import SearchBox from "../ui/SearchBox";
import PaginationButtons from "../ui/PaginationButtons";
import WithLoaderAndError from "../ui/WithLoaderAndError";
import { getImgUrl } from "../../utils/getImgUrl";
import useFetch from "../../utils/useFetch";
import { config } from "../../config";

const SelectCustomerDrawer = ({
  show,
  setShow,
  selectedCustomerId,
  placement = "end",
  onSelect,
  afterSelect,
  closeButton = false,
}) => {
  const PAGE_SIZE = 30;
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);

  const {
    data: customers,
    isValidating,
    error: customersError,
  } = useFetch({
    url: `${config.api.phoenix}/customers?pageSize=${PAGE_SIZE}&page=${page}&query=${filter}`,
    // fallbackData: customersProp,
  });

  // set customer
  const handleCustomerClick = (c) => {
    onSelect((prevClient) => ({
      ...prevClient,
      customer: c,
    }));
    setShow(false);
    if (afterSelect && c !== null) {
      afterSelect(c.id);
    }
  };

  return (
    <Offcanvas
      show={show}
      onHide={() =>
        (selectedCustomerId != null || closeButton) && setShow(false)
      }
      placement={placement}
    >
      <Offcanvas.Header
        className="border-bottom p-2"
        {...(selectedCustomerId != null || closeButton
          ? { closeButton: true }
          : {})}
      >
        <div className="w-100 me-1">
          <SearchBox
            query={filter}
            setQuery={setFilter}
            placeholder={"Search by customer name, code or id"}
            /*start={() => (
              <InputGroup.Text>
                <FaStore />
              </InputGroup.Text>
            )}*/
          />
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">
        <WithLoaderAndError
          isLoading={!customers && !customersError}
          error={customersError}
          stacked
        >
          {customers && !!customers.length ? (
            <ListGroup variant="flush" className="w-100 align-self-start">
              {/* {
                deselectCustomerOption ? 
                <ListGroup.Item
                  action
                  onClick={() => handleCustomerClick(null)}
                >
                </ListGroup.Item> : null
              } */}
              {customers.map((c) => (
                <ListGroup.Item
                  key={c.id}
                  action
                  onClick={() => handleCustomerClick(c)}
                  variant={selectedCustomerId === c.id ? "success" : null}
                  className="p-0"
                >
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        width: "48px",
                        height: "48px",
                      }}
                      className="border-end me-2 overflow-hidden position-relative"
                    >
                      {c.logoFileId ? (
                        <div
                          style={{
                            width: "48px",
                            height: "48px",
                            background: `url(${getImgUrl(c.logoFileId, {
                              width: 48,
                              height: 48,
                              resizeMode: "Cover",
                            })}) no-repeat center`,
                          }}
                        />
                      ) : (
                        <FaStore className="icon-as-img" />
                      )}
                    </div>
                    <div className="lh-sm">
                      <div className="fw-semibold">{c.name}</div>
                      <small className="text-muted font-monospace">
                        {c.code}
                      </small>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <div className="text-muted p-3">No customers found</div>
          )}
        </WithLoaderAndError>
      </Offcanvas.Body>
      <Offcanvas.Header className="bottom-0 p-2 border-top">
        {customers?.length ? (
          <PaginationButtons
            page={page}
            setPage={setPage}
            pageSize={PAGE_SIZE}
            isLoading={isValidating}
          />
        ) : null}
      </Offcanvas.Header>
    </Offcanvas>
  );
};

export default SelectCustomerDrawer;
