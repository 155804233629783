import React from "react";
import { Button } from "react-bootstrap";
import {
  CgChevronRight,
  CgPushChevronRight,
  CgChevronLeft,
  CgPushChevronLeft,
} from "react-icons/cg";
import parseLinkHeader from "../../utils/parseLinkHeader";

const PaginationFromHeader = ({
  current,
  linkStr,
  variant = "outline-secondary",
  size = "sm",
  setPage,
  showButtons = true,
}) => {
  const links = parseLinkHeader(linkStr);

  const PageBtn = ({
    page,
    isDisabled = false,
    label,
    icon: Icon,
    className,
  }) => {
    return (
      <Button
        variant={variant}
        disabled={isDisabled}
        onClick={() => setPage(+page)}
        title={`${label}${page && page > 1 ? ` (${page})` : ""}`}
        size={size}
        className={className}
      >
        <Icon className="btn-icon" />
      </Button>
    );
  };

  return (
    <div>
      {showButtons ? (
        <PageBtn
          page={links?.first?.page}
          isDisabled={!links?.first}
          label="First"
          icon={CgPushChevronLeft}
        />
      ) : null}
      {showButtons ? (
        <PageBtn
          page={links?.prev?.page}
          isDisabled={!links?.first}
          label="Previous"
          icon={CgChevronLeft}
          className="ms-1"
        />
      ) : null}

      <span
        className={`btn cursor-default ${
          size === "sm" ? "btn-sm" : size === "lg" ? "btn-lg" : ""
        }`}
      >
        {`${current} of ${!!links?.last?.page ? links?.last?.page : current}`}
      </span>
      {showButtons ? (
        <PageBtn
          page={links?.next?.page}
          isDisabled={!links?.next}
          label="Next"
          icon={CgChevronRight}
          className="me-1"
        />
      ) : null}
      {showButtons ? (
        <PageBtn
          page={links?.last?.page}
          isDisabled={!links?.last}
          label="Last"
          icon={CgPushChevronRight}
        />
      ) : null}
    </div>
  );
};

export default PaginationFromHeader;
