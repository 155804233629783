import React, { useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { BsTrash, BsX } from "react-icons/bs";
import bird from "../../assets/il-phatix.svg";
import { FaSkullCrossbones } from "react-icons/fa6";

const BtnWithDialog = ({
  text = <div className="py-3">Are you sure?</div>,
  smallText,
  modalSize = "",
  title,
  triggerEl: Trigger = Button,
  onTrigger,
  onConfirm,
  onCancel,
  isDisabled,
  icon: Icon = BsTrash,
  btnText = "Delete",
  variant = "danger",
  btnSize = "sm",
  img = variant === "danger" ? bird : null,
  children,
}) => {
  const [show, setShow] = useState(false);

  const handleTrigger = () => {
    onTrigger && onTrigger();
    setShow(true);
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setShow(false);
  };

  const handleConfirm = () => {
    onConfirm && onConfirm();
    setShow(false);
  };

  return (
    <>
      <Trigger
        variant={variant}
        size={btnSize}
        disabled={isDisabled}
        onClick={handleTrigger}
      >
        <Icon className="me-1" /> {btnText}
      </Trigger>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        fullscreen="mo-down"
        scrollable
        size={modalSize}
      >
        <Modal.Header closeButton className="border-0 bg-light py-3">
          {title ? <Modal.Title>{title}</Modal.Title> : null}
        </Modal.Header>

        <Modal.Body className="bg-light pb-4 text-center">
          {
            <Image
              fluid
              src={img}
              alt="danger"
              className="d-block mx-auto px-5 mb-4"
              style={{ width: "200px" }}
            />
          }
          {text}
          <div className="small text-muted">{smallText}</div>
          {children}
        </Modal.Body>
        <Modal.Footer className="flex-nowrap bg-light border-0 p-4 pt-0">
          <Button
            variant="outline-secondary"
            onClick={handleCancel}
            className="w-100"
          >
            <BsX className="me-1" /> No, cancel
          </Button>
          <Button variant="danger" onClick={handleConfirm} className="w-100">
            <FaSkullCrossbones className="me-1" /> Yes, delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BtnWithDialog;
