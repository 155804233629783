import React from "react";
import { Card, Dropdown, DropdownButton, Nav } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { BsDownload } from "react-icons/bs";
import Tab from "react-bootstrap/Tab";

import Layout from "../../layout/Layout";
import { config } from "../../../config";
import useFetch from "../../../utils/useFetch";
import WithLoaderAndError from "../../ui/WithLoaderAndError";
import exportCSV from "../../../utils/exportCVS";
import CustomerDetailsAddresses from "./CustomerAddresses/CustomerDetailsAddresses";
import CustomerDetailsContactPersons from "./CustomerContactPersons/CustomerDetailsContactPersons";
import CustomerDetailsSupplier from "./CustomerSupplier/CustomerDetailsSupplier";
import CustomerDetailsGeneral from "./CustomerGeneral/CustomerDetailsGeneral";
import CustomerDetailsMarketingProfile from "./CustomerMarketingProfile/CustomerDetailsMarketingProfile";
import NoResults from "../../ui/NoResults";
import DeleteCustomerButton from "./DeleteCustomerButton";

const CustomerDetail = () => {
  const { customerId } = useParams();

  const {
    data: customer,
    error: customerError,
    mutate: refreshCustomer,
  } = useFetch({
    url: `${config.api.phoenix}/customers/${customerId}`,
    customerId: customerId,
  });

  const {
    data: mp,
    error: mpError,
    mutate: refreshMp,
  } = useFetch({
    url: `${config.api.phoenix}/marketingprofiles/${customerId}`,
    customerId: customerId,
  });
  const { data: contactDetails, error: contactDetailsError } = useFetch({
    url: `${config.api.phoenix}/customers/${customerId}/contactdetails`,
    customerId: customerId,
  });

  const tabList = [
    "General",
    "Marketing profile",
    "Addresses",
    "Contact persons",
    "Supplier",
    "Programs",
    "Variants",
    "Orders",
  ];

  const { addresses, ...customerWithoutAddresses } = !!customer ? customer : {};

  /*value and label are required elements. 
  Having no type defined gives use text as a default
  Required is optional
  */
  const formSettings = {
    id: {
      value: customer?.id,
      label: "Customer ID",
    },
    code: {
      value: customer?.code,
      label: "Code*",
      required: true,
    },
    name: {
      value: customer?.name,
      label: "Name*",
      required: true,
    },
    marketingName: {
      value: customer?.marketingName,
      label: "Marketing name",
      text: (
        <>
          When filled in, <span className="font-monospace">marketingName</span>{" "}
          is displayed in place of <span className="font-monospace">name</span>
        </>
      ),
    },
    description: {
      value: customer?.description,
      label: "Description",
      text: "Plain-text or HTML (HTML editor to be added)",
    },
    accountManager: {
      value: customer?.accountManager,
      label: "Account manager",
    },
    salesManager: {
      value: customer?.salesManager,
      label: "Sales manager",
    },
    projectManager: {
      value: customer?.projectManager,
      label: "Project manager",
    },
    openingTimes: {
      value: customer?.openingTimes,
      label: "Opening times",
    },
    active: {
      value: customer?.active,
      label: "Active",
    },
    isTestCustomer: {
      value: customer?.isTestCustomer,
      label: "Is test customer",
    },
    onHold: {
      value: customer?.onHold,
      label: "on Hold",
    },
    language: {
      value: customer?.language,
      label: "Language",
      selectOptions: [
        { value: "NL", label: "NL" },
        { value: "EN", label: "EN" },
        { value: "DE", label: "DE" },
        { value: "VL", label: "VL" },
        { value: "FR", label: "FR" },
        { value: "ES", label: "ES" },
        { value: "AT-DE", label: "AT-DE" },
        { value: "CH-DE", label: "CH-DE" },
        { value: "CH-FR", label: "CH-FR" },
        { value: "CH-IT", label: "CH-IT" },
      ],
    },
    onHoldDescription: {
      value: customer?.onHoldDescription,
      label: "Reason on Hold",
    },
    internalLabels: {
      value: customer?.internalLabels,
      label: "Internal labels",
    },
    externalLabels: {
      value: customer?.externalLabels,
      label: "External labels",
    },
    addresses: {
      value: customer?.addresses,
      label: "Adresses",
    },
  };

  return (
    <Layout pageTitle={customer?.name || `...`}>
      <WithLoaderAndError
        isLoading={
          !customer && !customerError && !contactDetailsError && !mpError
        }
        error={customerError || contactDetailsError || mpError}
        fullpage
      >
        {customer !== null ? (
          <Card className="card-main border-0">
            {/* Filter tab content */}
            <Tab.Container defaultActiveKey={tabList[0]}>
              <Card.Header
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Nav variant="tabs" style={{ flex: "auto" }}>
                  {tabList.map((tab) => (
                    <Nav.Item key={tab}>
                      <Nav.Link eventKey={tab}>{tab}</Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
                <div className="d-flex align-items-center justify-content-center">
                  <DropdownButton
                    title={
                      <>
                        <BsDownload className="me-1" /> Export
                      </>
                    }
                    size="sm"
                    variant="outline-dark"
                    style={{
                      position: "relative",
                      flex: "initial",
                      paddingRight: 10,
                    }}
                  >
                    <Dropdown.Header>Export .csv</Dropdown.Header>
                    <Dropdown.Item
                      as="button"
                      disabled={!customerWithoutAddresses}
                      onClick={() =>
                        exportCSV(
                          customerWithoutAddresses,
                          `${customerId} Customer details`
                        )
                      }
                    >
                      Customer details
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      disabled={!addresses?.length}
                      onClick={() =>
                        exportCSV(addresses, `${customerId} Customer addresses`)
                      }
                    >
                      Addresses
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      disabled={!contactDetails || !contactDetails.length}
                      onClick={() =>
                        exportCSV(
                          contactDetails,
                          `${customerId} Customer contact details`
                        )
                      }
                    >
                      Contact details
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Header>Copy JSON</Dropdown.Header>
                    <Dropdown.Item
                      as="button"
                      disabled={
                        !customer ||
                        !mp ||
                        !contactDetails ||
                        !contactDetails.length
                      }
                      onClick={() =>
                        !!navigator &&
                        navigator.clipboard.writeText(
                          JSON.stringify({ customer, mp, contactDetails })
                        )
                      }
                    >
                      Copy to clipboard
                    </Dropdown.Item>
                  </DropdownButton>

                  <DeleteCustomerButton
                    customerName={customer?.name}
                    customerId={customer?.id}
                  />
                </div>
              </Card.Header>
              <Card.Body>
                <Tab.Content>
                  <Tab.Pane eventKey="General">
                    <CustomerDetailsGeneral
                      customer={customer}
                      formSettings={formSettings}
                      refreshCustomer={() => refreshCustomer()}
                      mp={mp}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Marketing profile">
                    <CustomerDetailsMarketingProfile
                      mp={mp}
                      refreshMp={() => refreshMp()}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Addresses">
                    <CustomerDetailsAddresses
                      customer={customer}
                      formSettings={formSettings}
                      refetch={() => refreshCustomer()}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Contact persons">
                    <CustomerDetailsContactPersons />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Supplier">
                    <CustomerDetailsSupplier />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        ) : (
          <NoResults
            children={
              <div className="text-muted mt-2">"No customer found"</div>
            }
          />
        )}
      </WithLoaderAndError>
    </Layout>
  );
};

export default CustomerDetail;
