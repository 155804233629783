import { useEffect, useState } from "react";
import { Button, ButtonGroup, Modal, Table } from "react-bootstrap";
import { config } from "../../../config";
import { useAuthContext } from "../../../context/AuthContext";
import SelectCustomerDrawer from "../../custom/SelectCustomerDrawer";
import fetcher from "../../../utils/fetcher";
import createDetailedErrorMessage from "../../../utils/detailMessageError";
import WithLoaderAndError from "../../ui/WithLoaderAndError";
import PaginationButtons from "../../ui/PaginationButtons";

const AddVariantByProfile = () => {
  const { client, setToasts, setClient } = useAuthContext();
  const [showDrawer, setShowDrawer] = useState(false); //modal for choosing customer. Appears if no customer is filtered yet
  const [showProfileModal, setShowProfileModal] = useState(false); //modal for selecting the profile
  const [showWarning, setShowWarning] = useState(false); //modal for final agreement

  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState({});

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 30;

  const handleProfiles = (customerId) => {
    fetcher({
      url: `${config.api.phoenix}/profiles?customerId=${
        customerId || client?.customer?.id
      }&filter=AvailableForCustomer&page=${page}&pageSize=${PAGE_SIZE}&extraInfo=true`,
      method: "GET",
      customerId: customerId,
      clientId: client?.id,
    })
      .then((res) => {
        if (res.data) {
          setProfiles(res.data);
          setLoading(false);
          setShowProfileModal(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "An error occured",
          delay: 30000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  //We first fetch the right programId and then for each profileVariant make a new variant
  const createVariantByProfile = () => {
    if (selectedProfile?.profileVariants?.length > 0) {
      fetcher({
        url: `${config.api.phoenix}/profiles/${selectedProfile.id}`,
        method: "GET",
        customerId: client?.customer?.id,
        clientId: client?.id,
      })
        .then((res) => {
          selectedProfile.profileVariants.forEach((variant, index) => {
            setTimeout(() => {
              //to prevent that Date.now generates the same id
              const payload = {
                name: variant.variantName,
                description: variant?.description,
                customerId: client?.customer?.id,
                programId: res.data.programId,
                treatmentId: variant.treatment.id,
                active: true,
                mailMomentId: variant.mailMoment.id,
                segmentId: variant.segment?.id || null,
              };

              fetcher({
                url: `${config.api.phoenix}/variants`,
                method: "POST",
                payload: payload,
                customerId: client?.customer?.id,
                clientId: client?.id,
              })
                .then(() => {
                  setToasts((currToasts) => [
                    ...currToasts,
                    {
                      id: Date.now(),
                      variant: "success",
                      heading: "Success!",
                      delay: 30000,
                      text: (
                        <div>
                          {"Variant "} <strong>{variant.variantName}</strong>{" "}
                          {"has been successfully created"}
                        </div>
                      ),
                    },
                  ]);
                })
                .catch((err) => {
                  setToasts((currToasts) => [
                    ...currToasts,
                    {
                      id: Date.now(),
                      variant: "danger",
                      heading: (
                        <div>
                          {"An error occurred for variant "}
                          <strong>{variant.variantName}</strong>
                        </div>
                      ),
                      delay: 30000,
                      text: createDetailedErrorMessage(err),
                    },
                  ]);
                });
            }, index * 10);
          });
        })
        .catch((err) => {
          setError(err);
        });
      setSelectedProfile({});
    }
  };

  return (
    <>
      <Button
        as={ButtonGroup}
        variant="success"
        size="sm"
        onClick={() => {
          if (!!client?.customer?.id) {
            handleProfiles();
          } else {
            setShowDrawer(true);
          }
        }}
      >
        Add Variant By Profile
      </Button>

      {/* Modal to select a profile for creating a new variant*/}
      <Modal
        show={showProfileModal}
        onHide={() => {
          setShowProfileModal(false);
          setSelectedProfile({});
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Select a profile to create a Variant`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WithLoaderAndError isLoading={loading} fullpage pageWithHeader>
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Profile name</th>
                    <th>Program name</th>
                  </tr>
                </thead>
                <tbody>
                  {profiles.map((item) => (
                    <tr key={item.id} onClick={() => setSelectedProfile(item)}>
                      <td
                        style={
                          selectedProfile.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.name}
                      </td>
                      <td
                        style={
                          selectedProfile.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.programName}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </WithLoaderAndError>
        </Modal.Body>
        <Modal.Footer
          className="d-flex justify-content-center"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div style={{ width: "30%" }}></div>
          <div>
            <PaginationButtons
              pageSize={PAGE_SIZE}
              page={page}
              setPage={setPage}
              isLoading={loading}
            />
          </div>
          <div style={{ width: "30%" }} className="d-flex justify-content-end">
            <div style={{ marginRight: 10 }}>
              <Button
                variant="secondary"
                onClick={() => {
                  setShowProfileModal(false);
                  setSelectedProfile({});
                }}
              >
                Cancel
              </Button>
            </div>
            <Button
              onClick={() => {
                setShowProfileModal(false);
                setShowWarning(true);
              }}
              disabled={Object.keys(selectedProfile).length === 0}
              variant="success"
            >
              Use selected
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Modal before creating variants with warning that new treatmens can be added this customer*/}
      <Modal
        size="lg"
        show={showWarning}
        onHide={() => {
          setShowWarning(false);
          setSelectedProfile({});
        }}
        style={{ marginTop: "100px" }}
      >
        <Modal.Header>
          <Modal.Title>Create a variant based on profile?</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ minHeight: "100px" }}
          className="d-flex justify-content-center align-items-center"
        >
          {`This action will add treatments to a customer based on profile: ${selectedProfile.name}`}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowWarning(false);
              setSelectedProfile({});
            }}
          >
            No, cancel
          </Button>
          <Button
            onClick={() => {
              setShowWarning(false);
              createVariantByProfile();
            }}
            disabled={Object.keys(selectedProfile).length === 0}
            variant="success"
          >
            Yes, create variant
          </Button>
        </Modal.Footer>
      </Modal>
      <SelectCustomerDrawer
        show={showDrawer}
        setShow={setShowDrawer}
        selectedCustomerId={client?.customer?.id}
        onSelect={setClient}
        afterSelect={handleProfiles}
      />
    </>
  );
};

export default AddVariantByProfile;
