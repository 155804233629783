import { ToastContainer, Toast } from "react-bootstrap";

const ToastHandler = ({ toasts, setToasts }) => {
  const renderToastContent = (toast) => {
    if (toast?.type === "success-delete") {
      return (
        <div className="mx-auto p-3 fade-in text-center">
          <div className="text-center">
            <div className="mx-auto mb-4" style={{ maxWidth: "140px" }}>
              {toast.img ? (
                <img
                  src={toast?.img}
                  alt="success"
                  className="img-fluid fade-in-grow-up delay-500ms"
                />
              ) : null}
            </div>
          </div>
          <div className="delay-each-child">
            {toast.titleText ? (
              <h3 className="fade-in-up delay">{toast.titleText}</h3>
            ) : null}
            {toast.text ? (
              <div className="fade-in-up delay mb-2">{toast.text}</div>
            ) : null}
          </div>
          <div className="mt-2 delay-each-child d-flex justify-content-center">
            {toast.link && (
              <a href={toast.link.href} className="btn btn-link link-light">
                {toast.link.text}
              </a>
            )}
          </div>
        </div>
      );
    }
    return (
      <>
        <div>
          {toast?.text ||
            (toast?.variant === "danger" ? "An unexpected error occurred" : "")}
        </div>
        {toast.img ? (
          <img
            src={toast.img}
            alt={toast?.heading}
            className="fade-in-grow-up delay-500ms"
            style={{
              position: "absolute",
              width: "80px",
              top: "-75px",
              left: "15px",
            }}
          />
        ) : null}
        {!!toast?.response?.data && Array.isArray(toast.response.data) ? (
          <div className="px-3 py-1 mt-2 bg-danger bg-opacity-25">
            {toast.response.data.map((errorLine, i) => (
              <code key={i} className="d-block my-2">
                {errorLine}
              </code>
            ))}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <ToastContainer
      position="bottom-end"
      className="position-fixed pe-3"
      style={{
        marginBottom: "calc(var(--footer-height)",
        padding: ".75rem",
      }}
    >
      {toasts?.map((toast) => (
        <Toast
          key={toast.id}
          onClose={() =>
            setToasts((curr) =>
              curr.filter((oldToast) => oldToast.id !== toast.id)
            )
          }
          className={`color-light bg-${toast?.variant || "danger"}-tint-10`}
          show
          delay={toast?.delay || 2000}
          autohide
        >
          {/* 
              id
              variant
              heading -> title
              delay
              text  -> message
              add img
            */}
          <Toast.Header closeVariant="white" className="pb-0">
            <strong className="me-auto fw-semibold">
              {toast.heading || (toast?.variant === "danger" ? "Error" : "")}
            </strong>
            <small className="font-monospace text-opacity-75">
              {toast?.code || ""}
            </small>
          </Toast.Header>
          <Toast.Body className="pt-0 small">
            {renderToastContent(toast)}
          </Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  );
};

export default ToastHandler;
