import React from "react";
import { Navigate } from "react-router-dom";

export const OnLogin = () => {
  // console.log("onLogin");
  //   return (
  //     <div>
  //       Login successful
  //       <Navigate to="/" />
  //     </div>
  //   );
  //   return <Navigate to="/" />;
  sessionStorage.setItem("loggedIn", true);
  return <Navigate to="/" />;
};
