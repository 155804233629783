import ProgramDetail2 from "./components/pages/ProgramDetail/ProgramDetail2";
import CustomerDetail from "./components/pages/CustomerDetail/CustomerDetail";
import Dashboard from "./components/pages/Dashboard";
import HelpSectionPage from "./components/pages/HelpSectionPage";
import Login from "./components/pages/Login";
import PageNotFound from "./components/pages/PageNotFound";
import Programs from "./components/pages/Programs";
import Variants from "./components/pages/Variants/Variants";
import Customers from "./components/pages/Customers"; // main
import Products from "./components/pages/Products";
import Packages from "./components/pages/Packages";
import Orders from "./components/pages/Orders";
import Reports from "./components/pages/Reports";
import ProductDetail from "./components/pages/ProductDetail";
import PackageDetail from "./components/pages/PackageDetail";
import ProgramDetail from "./components/pages/ProgramDetail";
import QSDetail from "./components/pages/QSDetail";
import ALDetail from "./components/pages/ALDetail/ALDetail";
import VariantDetail from "./components/pages/Variants/VariantDetail";
import CustomerView from "./components/pages/CustomerDetail/CustomerView";
import Examples from "./components/pages/Examples/Examples";
import PageSection from "./components/pages/Examples/PageSection";
import { OnLogin } from "./components/OnLogin";
import { OnLogout } from "./components/OnLogout";

const routes = [
  {
    element: <Dashboard />,
    index: true,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "onlogin",
    element: <OnLogin />,
  },
  {
    path: "onlogout",
    element: <OnLogout />,
  },
  {
    path: "customers",
    element: <Customers />,
    children: [
      {
        path: "view/:itemId",
        element: <CustomerView />,
      },
    ],
  },
  {
    path: "customers/:customerId",
    element: <CustomerDetail />,
  },
  {
    path: "variants",
    element: <Variants />,
  },
  {
    path: "variants/:variantId",
    element: <VariantDetail />,
  },
  {
    path: "programs/:programType?",
    element: <Programs />,
  },
  // {
  //   path: "programs",
  //   element: <Programs />,
  // },
  {
    path: "programs/:programType/:id",
    element: <ProgramDetail />,
  },
  {
    path: "programX/:programId",
    element: <ProgramDetail2 />,
  },
  {
    path: "products",
    element: <Products />,
  },
  {
    path: "products/:id",
    element: <ProductDetail />,
  },
  {
    path: "packages",
    element: <Packages />,
  },
  {
    path: "packages/:id",
    element: <PackageDetail />,
  },
  {
    path: "orders",
    element: <Orders />,
  },
  {
    path: "multiclient/reports",
    element: <Reports />,
  },
  {
    path: "reports",
    element: <Reports />,
  },
  {
    path: "reports/:chartType",
    element: <Reports />,
  },
  {
    path: "reports/quicksight/:reportId/:viewId",
    element: <QSDetail />,
  },
  {
    path: "reports/actionlist/:reportId/:viewId",
    element: <ALDetail />,
  },
  {
    path: "help",
    element: <HelpSectionPage />,
  },
  {
    path: "help/:categoryId",
    element: <HelpSectionPage />,
  },
  {
    path: "examples",
    element: <Examples />,
    children: [
      {
        path: ":pageId",
        element: <PageSection />,
      },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];

export default routes;
