import { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { BsFunnelFill, BsX } from "react-icons/bs";
import SelectCustomerDrawer from "../../custom/SelectCustomerDrawer";
import { useAuthContext } from "../../../context/AuthContext";

const CustomerFilter = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const { client, setClient } = useAuthContext();

  return (
    <>
      <ButtonGroup size="sm">
        <Button
          variant={!!client?.customer ? "secondary" : "outline-secondary"}
          onClick={() => setShowDrawer(true)}
        >
          <BsFunnelFill style={{ marginRight: 5 }} /> Customers
        </Button>

        {!!client?.customer && (
          <Button
            variant="danger"
            onClick={() => {
              setClient((prev) => ({ ...prev, customer: null }));
            }}
          >
            <BsX />
          </Button>
        )}
      </ButtonGroup>
      <SelectCustomerDrawer
        show={showDrawer}
        setShow={setShowDrawer}
        selectedCustomerId={client?.customer?.id}
        onSelect={setClient}
        closeButton={true}
      />
    </>
  );
};

export default CustomerFilter;
